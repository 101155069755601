// Data
import { I_AXIOS } from "@/hooks/requests/configRequest.model";
import { FIELD_NAMES_ADAPTER } from "@/hooks/requests/it4GetLoadPhotoFields/it4GetLoadPhotoFields.model.js";

// Hooks
import { defineStore } from "pinia";
import { ref, reactive, computed, watch } from "vue";

// Types
import type {
    I_DownloadPhotos_Field,
    I_DownloadPhotos_Field_Names,
    I_DownloadPhotos_FieldLineData,
    I_DownloadPhotos_photoSetData,
} from "./downloadPhotos.types";
import type { I_it4GetLoadPhotoField_DataField } from "@/hooks/requests/it4GetLoadPhotoFields/it4GetLoadPhotoFields.types";

/**
 * * 🆂🆃🅾🆁🅴 [Download Photos]
 * */
export const useDownloadPhotosStore = defineStore("downloadPhotos", () => {
    // |-------|
    // | STATE |
    // |-------|

    /** Значения поля "Лицевая сторона с включенным экраном" */
    const frontImei = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Экран с информацией о состоянии батареи" */
    const frontBattery = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Модель" */
    const frontModel = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Встроенная память */
    const frontCapacity = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Лицевая сторона" */
    const front = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Задняя сторона" */
    const back = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Левая боковая грань" */
    const leftSide = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Правая боковая грань" */
    const rightSide = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Верхняя грань" */
    const topSide = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Значения поля "Нижняя грань" */
    const bottomSide = reactive<I_DownloadPhotos_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        hideLoadButton: false,
    });

    /** Получить наименования Полей для загрузки фото */
    const fieldsLineData = ref<I_DownloadPhotos_FieldLineData>(null);

    /** Получить фотосет ID после загрузки фото в ИТ4 */
    const photoSetData = ref<I_DownloadPhotos_photoSetData>(null);

    /** сгенерированная ссылка на QR-код */
    const qrPath = ref<null | string>(null);

    /** отметка про ошибку сети при работе с фотосетом */
    const notificationNetworkError = ref<boolean>(false);

    // |----------|
    // | COMPUTED |
    // |----------|

    /** Названия (name='...') типовых полей "Загрузить фото" */
    const fieldNamesData = computed<I_DownloadPhotos_Field_Names[]>(() => {
        return fieldsLineData.value.map(
            (lineData) => FIELD_NAMES_ADAPTER[lineData.side]
        );
    });

    /** Ко всем ли полям загружены фото? */
    const isAcceptedToNext = computed<boolean>(() => {
        const filedObject = {
            frontImei: frontImei,
            frontBattery: frontBattery,
            frontModel: frontModel,
            frontCapacity: frontCapacity,
            front: front,
            back: back,
            leftSide: leftSide,
            rightSide: rightSide,
            topSide: topSide,
            bottomSide: bottomSide,
        };

        return fieldNamesData.value.every((filedName) => {
            return filedObject[filedName].isValid;
        });
    });

    // |---------|
    // | METHODS |
    // |---------|

    /** Сбросить значения STORE на по-умолчанию */
    const restoreToDefault = () => {
        [
            frontImei,
            frontBattery,
            frontModel,
            frontCapacity,
            front,
            back,
            leftSide,
            rightSide,
            topSide,
            bottomSide,
        ].forEach((el) => {
            el.isLoading = false;
            el.content = "/img/screen_front_imei.png";
            el.isValid = false;
            el.wasUploaded = false;
            el.placeholder = "";
            el.hideLoadButton = false;
            delete el.comment;
            delete el.message;
        });

        photoSetData.value = null;
        fieldsLineData.value = null;
        qrPath.value = null;
    };

    /** Обнулить фото у поля */
    const removePhotoFromFiled = function (
        filedName: I_DownloadPhotos_Field_Names
    ) {
        (this[filedName] as I_DownloadPhotos_Field).content =
            "/img/screen_front_imei.png";
        (this[filedName] as I_DownloadPhotos_Field).placeholder = "";
        (this[filedName] as I_DownloadPhotos_Field).isValid = false;
        (this[filedName] as I_DownloadPhotos_Field).wasUploaded = false;
    };

    /** Проверяльщик были ли загрузки у полей по QR */
    const qrUploadChecker = (
        payload: I_it4GetLoadPhotoField_DataField,
        ind: number
    ) => {
        const fieldName = FIELD_NAMES_ADAPTER[payload.side];

        const filedObject = {
            frontImei: frontImei,
            frontBattery: frontBattery,
            frontModel: frontModel,
            frontCapacity: frontCapacity,
            front: front,
            back: back,
            leftSide: leftSide,
            rightSide: rightSide,
            topSide: topSide,
            bottomSide: bottomSide,
        };

        if (payload.wasUploaded) {
            filedObject[fieldName].wasUploaded = payload.wasUploaded;
            filedObject[
                fieldName
            ].content = `${I_AXIOS.IT4_HOST_PREVIEW}/attach/get2?ID=${payload.cid}`;
            filedObject[fieldName].isValid = true;
            filedObject[fieldName].hideLoadButton = true;
            filedObject[fieldName].placeholder = payload.cid;

            // Обновить данные у полей в сторе
            fieldsLineData.value[ind].cid = payload.cid;
            fieldsLineData.value[ind].wasUploaded = true;
        }
    };

    // |-------|
    // | WATCH |
    // |-------|

    watch(fieldsLineData, () => {
        if (fieldsLineData.value === null) return;

        const filedObject = {
            frontImei: frontImei,
            frontBattery: frontBattery,
            frontModel: frontModel,
            frontCapacity: frontCapacity,
            front: front,
            back: back,
            leftSide: leftSide,
            rightSide: rightSide,
            topSide: topSide,
            bottomSide: bottomSide,
        };

        fieldNamesData.value.forEach((el, ind) => {
            filedObject[
                el
            ].content = `${I_AXIOS.IT4_HOST}/attach/get2/${fieldsLineData.value[ind].cid}`;

            if (fieldsLineData.value[ind].wasUploaded) {
                filedObject[el].wasUploaded =
                    fieldsLineData.value[ind].wasUploaded;
                filedObject[
                    el
                ].content = `${I_AXIOS.IT4_HOST_PREVIEW}/attach/get2?ID=${fieldsLineData.value[ind].cid}`;
                filedObject[el].isValid = true;
                filedObject[el].hideLoadButton = true;
                filedObject[el].placeholder = fieldsLineData.value[ind].cid;
            }
        });
    });

    return {
        // * 🆂🆃🅾🆁🅴
        frontImei,
        frontBattery,
        frontModel,
        frontCapacity,
        front,
        back,
        leftSide,
        rightSide,
        topSide,
        bottomSide,

        // * 🅳🅰🆃🅰
        fieldsLineData,
        photoSetData,
        qrPath,
        notificationNetworkError,

        // * 🅲🅾🅼🅿🆄🆃🅴🅳
        fieldNamesData,
        isAcceptedToNext,

        // * 🅼🅴🆃🅷🅾🅳🆂
        restoreToDefault,
        removePhotoFromFiled,
        qrUploadChecker,
    };
});
