// Hooks
import { defineStore } from "pinia";
import { computed, ref } from "vue";

// Types
import type { I_HowYouRateDevice } from "./howYouRateDevice.types";

/**
 * * 🆂🆃🅾🆁🅴 [How You Rate Device]
 * */
export const useHowYouRateDeviceStore = defineStore("howYouRateDevice", () => {
    // |-------|
    // | STATE |
    // |-------|

    /** Состояние устройства - Как новое, Хорошее, С дефектами */
    const rate = ref<I_HowYouRateDevice["rate"]>(null);

    /** Состояние - "Q" | "B" | "C" */
    const grade = ref<I_HowYouRateDevice["grade"]>(null);

    /** Шаг назад */
    const isBack = ref<I_HowYouRateDevice["isBack"]>(true);

    /**
     * Определенный грейд устройства (для [HandedDevice])
     * ⓘ Используются/обновляются далее для экрана "evaluationResult"
     */
    const deviceGrade = ref<I_HowYouRateDevice["deviceGrade"]>(null);

    /**
     * Ошибка при определении грейда (для [HandedDevice])
     * ⓘ Используются/обновляются далее для экрана "evaluationResult"
     */
    const deviceNoGrade = ref<I_HowYouRateDevice["deviceNoGrade"]>(false);

    /**
     * Отвечает за режим "Оценка недоступна"
     */
    const isNoPhotoSet = ref<I_HowYouRateDevice["isNoPhotoSet"]>(false);

    /**
     * Расчетные данные для Плитки предварительной цены выкупаемого устройства
     */
    const calculatedDeviceData =
        ref<I_HowYouRateDevice["calculatedDeviceData"]>(null);

    /**
     * Загруженные данные для Плитки предварительной цены выкупаемого устройства
     */
    const registeringDeviceData =
        ref<I_HowYouRateDevice["registeringDeviceData"]>(null);

    /**
     * Загруженные данные для вопроса по состояниям устройства
     * https://preview.it4profit.com/attach/get2/{imgCid}
     */
    const questionData = ref<I_HowYouRateDevice["questionData"]>(null);

    // |----------|
    // | COMPUTED |
    // |----------|
    /**
     * Доступные типы подписаний договора
     */
    const signingTypes = computed<string[]>(() => registeringDeviceData.value?.signingTypes ?? ["TRUSTME_KZ", "WITHOUT_SMS"]);

    /**
     * Возможность для клиента выбрать тип подписания договора
     */
    const canChooseSigningType = computed<boolean>(() => signingTypes.value.length > 1);

    /**
     * Если возможность выбрать тип подписания недоступна, то взять первый тип как предвыбранный
     */
    const prechoosenSigningType = computed<string>(() => signingTypes.value?.[0] ?? "");

    // |---------|
    // | METHODS |
    // |---------|
    const showWarningNotificationMinOrder = (minPrice: number): boolean => {
        if (!calculatedDeviceData.value) return false;

        return calculatedDeviceData.value.panelCostOriginal <= minPrice;
    };

    /** Сбросить значения STORE на по-умолчанию */
    const restoreToDefault = () => {
        rate.value = null;
        grade.value = null;
        isBack.value = true;
        deviceGrade.value = null;
        deviceNoGrade.value = false;
        isNoPhotoSet.value = false;
        calculatedDeviceData.value = null;
        registeringDeviceData.value = null;
        questionData.value = null;
    };

    // |-------|
    // | WATCH |
    // |-------|

    return {
        // * 🆂🆃🅾🆁🅴
        rate,
        grade,
        isBack,
        calculatedDeviceData,
        deviceGrade,
        deviceNoGrade,
        registeringDeviceData,
        questionData,
        isNoPhotoSet,

        // * 🅲🅾🅼🅿🆄🆃🅴🅳
        signingTypes,
        canChooseSigningType,
        prechoosenSigningType,

        // * 🅼🅴🆃🅷🅾🅳🆂
        restoreToDefault,
        showWarningNotificationMinOrder,
    };
});
